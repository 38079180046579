import React from 'react'
import PropTypes from 'prop-types'
import Wysiwyg from 'src/components/Wysiwyg'
import Section from 'src/components/Section'
import Heading from 'src/components/Heading'
import { Grid } from '@material-ui/core'
import { getThemeVar } from 'src/styles/mixins'

export const DefaultTemplate = ({ title, content }) => {
  return (
    <Section background={getThemeVar('color.lightblue')} hasPaddingLarge paddingLargeValues={{ xs: '48px 0 96px 0', lg: '100px 0 148px 0' }}>
      <Heading>
        {title}
      </Heading>
      <Grid container>
        <Grid item xs={12} md={10}>
          <Wysiwyg>
            {content}
          </Wysiwyg>
        </Grid>
      </Grid>
    </Section>
  )
}

DefaultTemplate.defaultProps = {
  content: '',
}

DefaultTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

export default DefaultTemplate
